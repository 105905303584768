{
    "10000002": {
        "Element": "Ice",
        "Consts": [
            "UI_Talent_S_Ayaka_01",
            "UI_Talent_S_Ayaka_02",
            "UI_Talent_U_Ayaka_02",
            "UI_Talent_S_Ayaka_03",
            "UI_Talent_U_Ayaka_01",
            "UI_Talent_S_Ayaka_04"
        ],
        "SkillOrder": [
            10024,
            10018,
            10019
        ],
        "Skills": {
            "10018": "Skill_S_Ayaka_01",
            "10019": "Skill_E_Ayaka",
            "10024": "Skill_A_01"
        },
        "ProudMap": {
            "10018": 232,
            "10019": 239,
            "10024": 231
        },
        "NameTextMapHash": 1006042610,
        "SideIconName": "UI_AvatarIcon_Side_Ayaka",
        "QualityType": "QUALITY_ORANGE"
    },
    "10000003": {
        "Element": "Wind",
        "Consts": [
            "UI_Talent_S_Qin_01",
            "UI_Talent_S_Qin_02",
            "UI_Talent_U_Qin_02",
            "UI_Talent_S_Qin_03",
            "UI_Talent_U_Qin_01",
            "UI_Talent_S_Qin_04"
        ],
        "SkillOrder": [
            10031,
            10033,
            10034
        ],
        "Skills": {
            "10031": "Skill_A_01",
            "10033": "Skill_S_Qin_02",
            "10034": "Skill_E_Qin_01"
        },
        "ProudMap": {
            "10031": 331,
            "10033": 332,
            "10034": 339
        },
        "NameTextMapHash": 3221566250,
        "SideIconName": "UI_AvatarIcon_Side_Qin",
        "QualityType": "QUALITY_ORANGE",
        "Costumes": {
            "200301": {
                "sideIconName": "UI_AvatarIcon_Side_QinCostumeSea",
                "icon": "UI_AvatarIcon_QinCostumeSea",
                "art": "UI_Costume_QinCostumeSea",
                "avatarId": 10000003
            },
            "200302": {
                "sideIconName": "UI_AvatarIcon_Side_QinCostumeWic",
                "icon": "UI_AvatarIcon_QinCostumeWic",
                "art": "UI_Costume_QinCostumeWic",
                "avatarId": 10000003
            }
        }
    },
    "10000005": {
        "Element": "Wind",
        "Consts": [
            "UI_Talent_S_PlayerWind_01",
            "UI_Talent_S_PlayerWind_02",
            "UI_Talent_U_PlayerWind_02",
            "UI_Talent_S_PlayerWind_03",
            "UI_Talent_U_PlayerWind_01",
            "UI_Talent_S_PlayerWind_04"
        ],
        "SkillOrder": [
            100543,
            10067,
            10068
        ],
        "Skills": {
            "10067": "Skill_S_PlayerWind_01",
            "10068": "Skill_E_PlayerWind_01",
            "100543": "Skill_A_01"
        },
        "NameTextMapHash": 1533656818,
        "ProudMap": {
            "10067": 732,
            "10068": 739,
            "100543": 730
        },
        "SideIconName": "UI_AvatarIcon_Side_PlayerBoy",
        "QualityType": "QUALITY_ORANGE"
    },
    "10000006": {
        "Element": "Electric",
        "Consts": [
            "UI_Talent_S_Lisa_01",
            "UI_Talent_S_Lisa_02",
            "UI_Talent_U_Lisa_02",
            "UI_Talent_S_Lisa_03",
            "UI_Talent_U_Lisa_01",
            "UI_Talent_S_Lisa_04"
        ],
        "SkillOrder": [
            10060,
            10061,
            10062
        ],
        "Skills": {
            "10060": "Skill_A_Catalyst_MD",
            "10061": "Skill_S_Lisa_01",
            "10062": "Skill_E_Lisa_01"
        },
        "ProudMap": {
            "10060": 431,
            "10061": 432,
            "10062": 439
        },
        "NameTextMapHash": 3344622722,
        "SideIconName": "UI_AvatarIcon_Side_Lisa",
        "QualityType": "QUALITY_PURPLE"
    },
    "10000007": {
        "Element": "Wind",
        "Consts": [
            "UI_Talent_S_PlayerWind_01",
            "UI_Talent_S_PlayerWind_02",
            "UI_Talent_U_PlayerWind_02",
            "UI_Talent_S_PlayerWind_03",
            "UI_Talent_U_PlayerWind_01",
            "UI_Talent_S_PlayerWind_04"
        ],
        "SkillOrder": [
            100553,
            10067,
            10068
        ],
        "Skills": {
            "10067": "Skill_S_PlayerWind_01",
            "10068": "Skill_E_PlayerWind_01",
            "100553": "Skill_A_01"
        },
        "NameTextMapHash": 3816664530,
        "ProudMap": {
            "10067": 732,
            "10068": 739,
            "100553": 731
        },
        "SideIconName": "UI_AvatarIcon_Side_PlayerGirl",
        "QualityType": "QUALITY_ORANGE"
    },
    "10000014": {
        "Element": "Water",
        "Consts": [
            "UI_Talent_S_Barbara_01",
            "UI_Talent_S_Barbara_02",
            "UI_Talent_U_Barbara_02",
            "UI_Talent_S_Barbara_03",
            "UI_Talent_U_Barbara_01",
            "UI_Talent_S_Barbara_04"
        ],
        "SkillOrder": [
            10070,
            10071,
            10072
        ],
        "Skills": {
            "10070": "Skill_A_Catalyst_MD",
            "10071": "Skill_S_Barbara_01",
            "10072": "Skill_E_Barbara_01"
        },
        "ProudMap": {
            "10070": 1431,
            "10071": 1432,
            "10072": 1439
        },
        "NameTextMapHash": 3775299170,
        "SideIconName": "UI_AvatarIcon_Side_Barbara",
        "QualityType": "QUALITY_PURPLE",
        "Costumes": {
            "201401": {
                "sideIconName": "UI_AvatarIcon_Side_BarbaraCostumeSummertime",
                "icon": "UI_AvatarIcon_BarbaraCostumeSummertime",
                "art": "UI_Costume_BarbaraCostumeSummertime",
                "avatarId": 10000014
            }
        }
    },
    "10000015": {
        "Element": "Ice",
        "Consts": [
            "UI_Talent_S_Kaeya_01",
            "UI_Talent_S_Kaeya_02",
            "UI_Talent_U_Kaeya_01",
            "UI_Talent_S_Kaeya_03",
            "UI_Talent_U_Kaeya_02",
            "UI_Talent_S_Kaeya_04"
        ],
        "SkillOrder": [
            10073,
            10074,
            10075
        ],
        "Skills": {
            "10073": "Skill_A_01",
            "10074": "Skill_S_Kaeya_01",
            "10075": "Skill_E_Kaeya_01"
        },
        "ProudMap": {
            "10073": 1531,
            "10074": 1532,
            "10075": 1539
        },
        "NameTextMapHash": 4119663210,
        "SideIconName": "UI_AvatarIcon_Side_Kaeya",
        "QualityType": "QUALITY_PURPLE"
    },
    "10000016": {
        "Element": "Fire",
        "Consts": [
            "UI_Talent_S_Diluc_01",
            "UI_Talent_S_Diluc_02",
            "UI_Talent_U_Diluc_01",
            "UI_Talent_S_Diluc_03",
            "UI_Talent_U_Diluc_02",
            "UI_Talent_S_Diluc_04"
        ],
        "SkillOrder": [
            10160,
            10161,
            10165
        ],
        "Skills": {
            "10160": "Skill_A_04",
            "10161": "Skill_S_Diluc_01_01",
            "10165": "Skill_E_Diluc_01"
        },
        "ProudMap": {
            "10160": 1631,
            "10161": 1632,
            "10165": 1639
        },
        "NameTextMapHash": 3608180322,
        "SideIconName": "UI_AvatarIcon_Side_Diluc",
        "QualityType": "QUALITY_ORANGE",
        "Costumes": {
            "201601": {
                "sideIconName": "UI_AvatarIcon_Side_DilucCostumeFlamme",
                "icon": "UI_AvatarIcon_DilucCostumeFlamme",
                "art": "UI_Costume_DilucCostumeFlamme",
                "avatarId": 10000016
            }
        }
    },
    "10000020": {
        "Element": "Electric",
        "Consts": [
            "UI_Talent_S_Razor_01",
            "UI_Talent_S_Razor_02",
            "UI_Talent_U_Razor_02",
            "UI_Talent_S_Razor_03",
            "UI_Talent_U_Razor_01",
            "UI_Talent_S_Razor_04"
        ],
        "SkillOrder": [
            10201,
            10202,
            10203
        ],
        "Skills": {
            "10201": "Skill_A_04",
            "10202": "Skill_S_Razor_01",
            "10203": "Skill_E_Razor_01"
        },
        "ProudMap": {
            "10201": 2031,
            "10202": 2032,
            "10203": 2039
        },
        "NameTextMapHash": 4160147242,
        "SideIconName": "UI_AvatarIcon_Side_Razor",
        "QualityType": "QUALITY_PURPLE"
    },
    "10000021": {
        "Element": "Fire",
        "Consts": [
            "UI_Talent_S_Ambor_01",
            "UI_Talent_S_Ambor_02",
            "UI_Talent_U_Ambor_02",
            "UI_Talent_S_Ambor_03",
            "UI_Talent_U_Ambor_01",
            "UI_Talent_S_Ambor_04"
        ],
        "SkillOrder": [
            10041,
            10032,
            10017
        ],
        "Skills": {
            "10017": "Skill_E_Ambor",
            "10032": "Skill_S_Ambor_01",
            "10041": "Skill_A_02"
        },
        "ProudMap": {
            "10017": 2139,
            "10032": 2132,
            "10041": 2131
        },
        "NameTextMapHash": 1966438658,
        "SideIconName": "UI_AvatarIcon_Side_Ambor",
        "QualityType": "QUALITY_PURPLE",
        "Costumes": {
            "202101": {
                "sideIconName": "UI_AvatarIcon_Side_AmborCostumeWic",
                "icon": "UI_AvatarIcon_AmborCostumeWic",
                "art": "UI_Costume_AmborCostumeWic",
                "avatarId": 10000021
            }
        }
    },
    "10000022": {
        "Element": "Wind",
        "Consts": [
            "UI_Talent_S_Venti_01",
            "UI_Talent_S_Venti_02",
            "UI_Talent_U_Venti_02",
            "UI_Talent_S_Venti_03",
            "UI_Talent_U_Venti_01",
            "UI_Talent_S_Venti_04"
        ],
        "SkillOrder": [
            10221,
            10224,
            10225
        ],
        "Skills": {
            "10221": "Skill_A_02",
            "10224": "Skill_S_Venti_01",
            "10225": "Skill_E_Venti_01"
        },
        "ProudMap": {
            "10221": 2231,
            "10224": 2232,
            "10225": 2239
        },
        "NameTextMapHash": 2466140362,
        "SideIconName": "UI_AvatarIcon_Side_Venti",
        "QualityType": "QUALITY_ORANGE"
    },
    "10000023": {
        "Element": "Fire",
        "Consts": [
            "UI_Talent_S_Xiangling_01",
            "UI_Talent_S_Xiangling_02",
            "UI_Talent_U_Xiangling_02",
            "UI_Talent_S_Xiangling_03",
            "UI_Talent_U_Xiangling_01",
            "UI_Talent_S_Xiangling_04"
        ],
        "SkillOrder": [
            10231,
            10232,
            10235
        ],
        "Skills": {
            "10231": "Skill_A_03",
            "10232": "Skill_S_Xiangling_01",
            "10235": "Skill_E_Xiangling_01"
        },
        "ProudMap": {
            "10231": 2331,
            "10232": 2332,
            "10235": 2339
        },
        "NameTextMapHash": 1130996346,
        "SideIconName": "UI_AvatarIcon_Side_Xiangling",
        "QualityType": "QUALITY_PURPLE"
    },
    "10000024": {
        "Element": "Electric",
        "Consts": [
            "UI_Talent_S_Beidou_02",
            "UI_Talent_S_Beidou_01",
            "UI_Talent_U_Beidou_01",
            "UI_Talent_S_Beidou_03",
            "UI_Talent_U_Beidou_02",
            "UI_Talent_S_Beidou_04"
        ],
        "SkillOrder": [
            10241,
            10242,
            10245
        ],
        "Skills": {
            "10241": "Skill_A_04",
            "10242": "Skill_S_Beidou_01",
            "10245": "Skill_E_Beidou_01"
        },
        "ProudMap": {
            "10241": 2431,
            "10242": 2432,
            "10245": 2439
        },
        "NameTextMapHash": 2646367730,
        "SideIconName": "UI_AvatarIcon_Side_Beidou",
        "QualityType": "QUALITY_PURPLE"
    },
    "10000025": {
        "Element": "Water",
        "Consts": [
            "UI_Talent_S_Xingqiu_01",
            "UI_Talent_S_Xingqiu_02",
            "UI_Talent_U_Xingqiu_01",
            "UI_Talent_S_Xingqiu_03",
            "UI_Talent_U_Xingqiu_02",
            "UI_Talent_S_Xingqiu_04"
        ],
        "SkillOrder": [
            10381,
            10382,
            10385
        ],
        "Skills": {
            "10381": "Skill_A_01",
            "10382": "Skill_S_Xingqiu_01",
            "10385": "Skill_E_Xingqiu_01"
        },
        "ProudMap": {
            "10381": 2531,
            "10382": 2532,
            "10385": 2539
        },
        "NameTextMapHash": 4197635682,
        "SideIconName": "UI_AvatarIcon_Side_Xingqiu",
        "QualityType": "QUALITY_PURPLE"
    },
    "10000026": {
        "Element": "Wind",
        "Consts": [
            "UI_Talent_S_Xiao_01",
            "UI_Talent_S_Xiao_02",
            "UI_Talent_U_Xiao_01",
            "UI_Talent_S_Xiao_03",
            "UI_Talent_U_Xiao_02",
            "UI_Talent_S_Xiao_04"
        ],
        "SkillOrder": [
            10261,
            10262,
            10265
        ],
        "Skills": {
            "10261": "Skill_A_03",
            "10262": "Skill_S_Xiao_01",
            "10265": "Skill_E_Xiao_01"
        },
        "ProudMap": {
            "10261": 2631,
            "10262": 2632,
            "10265": 2639
        },
        "NameTextMapHash": 1021947690,
        "SideIconName": "UI_AvatarIcon_Side_Xiao",
        "QualityType": "QUALITY_ORANGE"
    },
    "10000027": {
        "Element": "Rock",
        "Consts": [
            "UI_Talent_S_Ningguang_01",
            "UI_Talent_S_Ningguang_05",
            "UI_Talent_U_Ningguang_02",
            "UI_Talent_S_Ningguang_03",
            "UI_Talent_U_Ningguang_01",
            "UI_Talent_S_Ningguang_04"
        ],
        "SkillOrder": [
            10271,
            10272,
            10274
        ],
        "Skills": {
            "10271": "Skill_A_Catalyst_MD",
            "10272": "Skill_S_Ningguang_01",
            "10274": "Skill_E_Ningguang_01"
        },
        "ProudMap": {
            "10271": 2731,
            "10272": 2732,
            "10274": 2739
        },
        "NameTextMapHash": 4127888970,
        "SideIconName": "UI_AvatarIcon_Side_Ningguang",
        "QualityType": "QUALITY_PURPLE",
        "Costumes": {
            "202701": {
                "sideIconName": "UI_AvatarIcon_Side_NingguangCostumeFloral",
                "icon": "UI_AvatarIcon_NingguangCostumeFloral",
                "art": "UI_Costume_NingguangCostumeFloral",
                "avatarId": 10000027
            }
        }
    },
    "10000029": {
        "Element": "Fire",
        "Consts": [
            "UI_Talent_S_Klee_01",
            "UI_Talent_S_Klee_02",
            "UI_Talent_U_Klee_01",
            "UI_Talent_S_Klee_03",
            "UI_Talent_U_Klee_02",
            "UI_Talent_S_Klee_04"
        ],
        "SkillOrder": [
            10291,
            10292,
            10295
        ],
        "Skills": {
            "10291": "Skill_A_Catalyst_MD",
            "10292": "Skill_S_Klee_01",
            "10295": "Skill_E_Klee_01"
        },
        "ProudMap": {
            "10291": 2931,
            "10292": 2932,
            "10295": 2939
        },
        "NameTextMapHash": 3339083250,
        "SideIconName": "UI_AvatarIcon_Side_Klee",
        "QualityType": "QUALITY_ORANGE"
    },
    "10000030": {
        "Element": "Rock",
        "Consts": [
            "UI_Talent_S_Zhongli_01",
            "UI_Talent_S_Zhongli_02",
            "UI_Talent_U_Zhongli_01",
            "UI_Talent_S_Zhongli_03",
            "UI_Talent_U_Zhongli_02",
            "UI_Talent_S_Zhongli_04"
        ],
        "SkillOrder": [
            10301,
            10302,
            10303
        ],
        "Skills": {
            "10301": "Skill_A_03",
            "10302": "Skill_S_Zhongli_01",
            "10303": "Skill_E_Zhongli_01"
        },
        "ProudMap": {
            "10301": 3031,
            "10302": 3032,
            "10303": 3039
        },
        "NameTextMapHash": 3862787418,
        "SideIconName": "UI_AvatarIcon_Side_Zhongli",
        "QualityType": "QUALITY_ORANGE"
    },
    "10000031": {
        "Element": "Electric",
        "Consts": [
            "UI_Talent_S_Fischl_01",
            "UI_Talent_S_Fischl_02",
            "UI_Talent_U_Fischl_01",
            "UI_Talent_S_Fischl_03",
            "UI_Talent_U_Fischl_02",
            "UI_Talent_S_Fischl_04"
        ],
        "SkillOrder": [
            10311,
            10312,
            10313
        ],
        "Skills": {
            "10311": "Skill_A_02",
            "10312": "Skill_S_Fischl_01",
            "10313": "Skill_E_Fischl_01"
        },
        "ProudMap": {
            "10311": 3131,
            "10312": 3132,
            "10313": 3139
        },
        "NameTextMapHash": 3277782506,
        "SideIconName": "UI_AvatarIcon_Side_Fischl",
        "QualityType": "QUALITY_PURPLE",
        "Costumes": {
            "203101": {
                "sideIconName": "UI_AvatarIcon_Side_FischlCostumeHighness",
                "icon": "UI_AvatarIcon_FischlCostumeHighness",
                "art": "UI_Costume_FischlCostumeHighness",
                "avatarId": 10000031
            }
        }
    },
    "10000032": {
        "Element": "Fire",
        "Consts": [
            "UI_Talent_S_Bennett_01",
            "UI_Talent_S_Bennett_02",
            "UI_Talent_U_Bennett_01",
            "UI_Talent_S_Bennett_03",
            "UI_Talent_U_Bennett_02",
            "UI_Talent_S_Bennett_04"
        ],
        "SkillOrder": [
            10321,
            10322,
            10323
        ],
        "Skills": {
            "10321": "Skill_A_01",
            "10322": "Skill_S_Bennett_01",
            "10323": "Skill_E_Bennett_01"
        },
        "ProudMap": {
            "10321": 3231,
            "10322": 3232,
            "10323": 3239
        },
        "NameTextMapHash": 968893378,
        "SideIconName": "UI_AvatarIcon_Side_Bennett",
        "QualityType": "QUALITY_PURPLE"
    },
    "10000033": {
        "Element": "Water",
        "Consts": [
            "UI_Talent_S_Tartaglia_01",
            "UI_Talent_S_Tartaglia_02",
            "UI_Talent_U_Tartaglia_01",
            "UI_Talent_S_Tartaglia_05",
            "UI_Talent_U_Tartaglia_02",
            "UI_Talent_S_Tartaglia_04"
        ],
        "SkillOrder": [
            10331,
            10332,
            10333
        ],
        "Skills": {
            "10331": "Skill_A_02",
            "10332": "Skill_S_Tartaglia_01",
            "10333": "Skill_E_Tartaglia_01"
        },
        "ProudMap": {
            "10331": 3331,
            "10332": 3332,
            "10333": 3339
        },
        "NameTextMapHash": 3847143266,
        "SideIconName": "UI_AvatarIcon_Side_Tartaglia",
        "QualityType": "QUALITY_ORANGE"
    },
    "10000034": {
        "Element": "Rock",
        "Consts": [
            "UI_Talent_S_Noel_01",
            "UI_Talent_S_Noel_02",
            "UI_Talent_U_Noel_01",
            "UI_Talent_S_Noel_03",
            "UI_Talent_U_Noel_02",
            "UI_Talent_S_Noel_04"
        ],
        "SkillOrder": [
            10341,
            10342,
            10343
        ],
        "Skills": {
            "10341": "Skill_A_04",
            "10342": "Skill_S_Noel_01",
            "10343": "Skill_E_Noel_01"
        },
        "ProudMap": {
            "10341": 3431,
            "10342": 3432,
            "10343": 3439
        },
        "NameTextMapHash": 1921418842,
        "SideIconName": "UI_AvatarIcon_Side_Noel",
        "QualityType": "QUALITY_PURPLE"
    },
    "10000035": {
        "Element": "Ice",
        "Consts": [
            "UI_Talent_S_Qiqi_01",
            "UI_Talent_S_Qiqi_02",
            "UI_Talent_U_Qiqi_01",
            "UI_Talent_S_Qiqi_03",
            "UI_Talent_U_Qiqi_02",
            "UI_Talent_S_Qiqi_04"
        ],
        "SkillOrder": [
            10351,
            10352,
            10353
        ],
        "Skills": {
            "10351": "Skill_A_01",
            "10352": "Skill_S_Qiqi_01",
            "10353": "Skill_E_Qiqi_01"
        },
        "ProudMap": {
            "10351": 3531,
            "10352": 3532,
            "10353": 3539
        },
        "NameTextMapHash": 168956722,
        "SideIconName": "UI_AvatarIcon_Side_Qiqi",
        "QualityType": "QUALITY_ORANGE"
    },
    "10000036": {
        "Element": "Ice",
        "Consts": [
            "UI_Talent_S_Chongyun_01",
            "UI_Talent_S_Chongyun_02",
            "UI_Talent_U_Chongyun_01",
            "UI_Talent_S_Chongyun_03",
            "UI_Talent_U_Chongyun_02",
            "UI_Talent_S_Chongyun_04"
        ],
        "SkillOrder": [
            10401,
            10402,
            10403
        ],
        "Skills": {
            "10401": "Skill_A_04",
            "10402": "Skill_S_Chongyun_01",
            "10403": "Skill_E_Chongyun_01"
        },
        "ProudMap": {
            "10401": 3631,
            "10402": 3632,
            "10403": 3639
        },
        "NameTextMapHash": 2876340530,
        "SideIconName": "UI_AvatarIcon_Side_Chongyun",
        "QualityType": "QUALITY_PURPLE"
    },
    "10000037": {
        "Element": "Ice",
        "Consts": [
            "UI_Talent_S_Ganyu_01",
            "UI_Talent_S_Ganyu_02",
            "UI_Talent_U_Ganyu_01",
            "UI_Talent_S_Ganyu_03",
            "UI_Talent_U_Ganyu_02",
            "UI_Talent_S_Ganyu_04"
        ],
        "SkillOrder": [
            10371,
            10372,
            10373
        ],
        "Skills": {
            "10371": "Skill_A_02",
            "10372": "Skill_S_Ganyu_01",
            "10373": "Skill_E_Ganyu_01"
        },
        "ProudMap": {
            "10371": 3731,
            "10372": 3732,
            "10373": 3739
        },
        "NameTextMapHash": 2679781122,
        "SideIconName": "UI_AvatarIcon_Side_Ganyu",
        "QualityType": "QUALITY_ORANGE"
    },
    "10000038": {
        "Element": "Rock",
        "Consts": [
            "UI_Talent_S_Albedo_01",
            "UI_Talent_S_Albedo_02",
            "UI_Talent_U_Albedo_01",
            "UI_Talent_S_Albedo_03",
            "UI_Talent_U_Albedo_02",
            "UI_Talent_S_Albedo_04"
        ],
        "SkillOrder": [
            10386,
            10387,
            10388
        ],
        "Skills": {
            "10386": "Skill_A_01",
            "10387": "Skill_S_Albedo_01",
            "10388": "Skill_E_Albedo_01"
        },
        "ProudMap": {
            "10386": 3831,
            "10387": 3832,
            "10388": 3839
        },
        "NameTextMapHash": 4108620722,
        "SideIconName": "UI_AvatarIcon_Side_Albedo",
        "QualityType": "QUALITY_ORANGE"
    },
    "10000039": {
        "Element": "Ice",
        "Consts": [
            "UI_Talent_S_Diona_01",
            "UI_Talent_S_Diona_02",
            "UI_Talent_U_Diona_01",
            "UI_Talent_S_Diona_03",
            "UI_Talent_U_Diona_02",
            "UI_Talent_S_Diona_04"
        ],
        "SkillOrder": [
            10391,
            10392,
            10395
        ],
        "Skills": {
            "10391": "Skill_A_02",
            "10392": "Skill_S_Diona_01",
            "10395": "Skill_E_Diona_01"
        },
        "ProudMap": {
            "10391": 3931,
            "10392": 3932,
            "10395": 3939
        },
        "NameTextMapHash": 1468367538,
        "SideIconName": "UI_AvatarIcon_Side_Diona",
        "QualityType": "QUALITY_PURPLE"
    },
    "10000041": {
        "Element": "Water",
        "Consts": [
            "UI_Talent_S_Mona_01",
            "UI_Talent_S_Mona_02",
            "UI_Talent_U_Mona_01",
            "UI_Talent_S_Mona_03",
            "UI_Talent_U_Mona_02",
            "UI_Talent_S_Mona_04"
        ],
        "SkillOrder": [
            10411,
            10412,
            10415
        ],
        "Skills": {
            "10411": "Skill_A_Catalyst_MD",
            "10412": "Skill_S_Mona_01",
            "10415": "Skill_E_Mona_01"
        },
        "ProudMap": {
            "10411": 4131,
            "10412": 4132,
            "10415": 4139
        },
        "NameTextMapHash": 1113306282,
        "SideIconName": "UI_AvatarIcon_Side_Mona",
        "QualityType": "QUALITY_ORANGE",
        "Costumes": {
            "204101": {
                "sideIconName": "UI_AvatarIcon_Side_MonaCostumeWic",
                "icon": "UI_AvatarIcon_MonaCostumeWic",
                "art": "UI_Costume_MonaCostumeWic",
                "avatarId": 10000041
            }
        }
    },
    "10000042": {
        "Element": "Electric",
        "Consts": [
            "UI_Talent_S_Keqing_01",
            "UI_Talent_S_Keqing_02",
            "UI_Talent_U_Keqing_01",
            "UI_Talent_S_Keqing_03",
            "UI_Talent_U_Keqing_02",
            "UI_Talent_S_Keqing_04"
        ],
        "SkillOrder": [
            10421,
            10422,
            10425
        ],
        "Skills": {
            "10421": "Skill_A_01",
            "10422": "Skill_S_Keqing_01",
            "10425": "Skill_E_Keqing_01"
        },
        "ProudMap": {
            "10421": 4231,
            "10422": 4232,
            "10425": 4239
        },
        "NameTextMapHash": 1864015138,
        "SideIconName": "UI_AvatarIcon_Side_Keqing",
        "QualityType": "QUALITY_ORANGE",
        "Costumes": {
            "204201": {
                "sideIconName": "UI_AvatarIcon_Side_KeqingCostumeFeather",
                "icon": "UI_AvatarIcon_KeqingCostumeFeather",
                "art": "UI_Costume_KeqingCostumeFeather",
                "avatarId": 10000042
            }
        }
    },
    "10000043": {
        "Element": "Wind",
        "Consts": [
            "UI_Talent_S_Sucrose_01",
            "UI_Talent_S_Sucrose_02",
            "UI_Talent_U_Sucrose_01",
            "UI_Talent_S_Sucrose_03",
            "UI_Talent_U_Sucrose_02",
            "UI_Talent_S_Sucrose_04"
        ],
        "SkillOrder": [
            10431,
            10432,
            10435
        ],
        "Skills": {
            "10431": "Skill_A_Catalyst_MD",
            "10432": "Skill_S_Sucrose_01",
            "10435": "Skill_E_Sucrose_01"
        },
        "ProudMap": {
            "10431": 4331,
            "10432": 4332,
            "10435": 4339
        },
        "NameTextMapHash": 1053433018,
        "SideIconName": "UI_AvatarIcon_Side_Sucrose",
        "QualityType": "QUALITY_PURPLE"
    },
    "10000044": {
        "Element": "Fire",
        "Consts": [
            "UI_Talent_S_Xinyan_01",
            "UI_Talent_S_Xinyan_02",
            "UI_Talent_U_Xinyan_01",
            "UI_Talent_S_Xinyan_03",
            "UI_Talent_U_Xinyan_02",
            "UI_Talent_S_Xinyan_04"
        ],
        "SkillOrder": [
            10441,
            10442,
            10443
        ],
        "Skills": {
            "10441": "Skill_A_04",
            "10442": "Skill_S_Xinyan_01",
            "10443": "Skill_E_Xinyan_01"
        },
        "ProudMap": {
            "10441": 4431,
            "10442": 4432,
            "10443": 4439
        },
        "NameTextMapHash": 4273845410,
        "SideIconName": "UI_AvatarIcon_Side_Xinyan",
        "QualityType": "QUALITY_PURPLE"
    },
    "10000045": {
        "Element": "Ice",
        "Consts": [
            "UI_Talent_S_Rosaria_01",
            "UI_Talent_S_Rosaria_02",
            "UI_Talent_U_Rosaria_01",
            "UI_Talent_S_Rosaria_03",
            "UI_Talent_U_Rosaria_02",
            "UI_Talent_S_Rosaria_04"
        ],
        "SkillOrder": [
            10451,
            10452,
            10453
        ],
        "Skills": {
            "10451": "Skill_A_03",
            "10452": "Skill_S_Rosaria_01",
            "10453": "Skill_E_Rosaria_01"
        },
        "ProudMap": {
            "10451": 4531,
            "10452": 4532,
            "10453": 4539
        },
        "NameTextMapHash": 4260733330,
        "SideIconName": "UI_AvatarIcon_Side_Rosaria",
        "QualityType": "QUALITY_PURPLE",
        "Costumes": {
            "204501": {
                "sideIconName": "UI_AvatarIcon_Side_RosariaCostumeWic",
                "icon": "UI_AvatarIcon_RosariaCostumeWic",
                "art": "UI_Costume_RosariaCostumeWic",
                "avatarId": 10000045
            }
        }
    },
    "10000046": {
        "Element": "Fire",
        "Consts": [
            "UI_Talent_S_Hutao_03",
            "UI_Talent_S_Hutao_01",
            "UI_Talent_U_Hutao_01",
            "UI_Talent_S_Hutao_02",
            "UI_Talent_U_Hutao_02",
            "UI_Talent_S_Hutao_04"
        ],
        "SkillOrder": [
            10461,
            10462,
            10463
        ],
        "Skills": {
            "10461": "Skill_A_03",
            "10462": "Skill_S_Hutao_01",
            "10463": "Skill_E_Hutao_01"
        },
        "ProudMap": {
            "10461": 4631,
            "10462": 4632,
            "10463": 4639
        },
        "NameTextMapHash": 1940919994,
        "SideIconName": "UI_AvatarIcon_Side_Hutao",
        "QualityType": "QUALITY_ORANGE"
    },
    "10000047": {
        "Element": "Wind",
        "Consts": [
            "UI_Talent_S_Kazuha_01",
            "UI_Talent_S_Kazuha_02",
            "UI_Talent_U_Kazuha_01",
            "UI_Talent_S_Kazuha_03",
            "UI_Talent_U_Kazuha_02",
            "UI_Talent_S_Kazuha_04"
        ],
        "SkillOrder": [
            10471,
            10472,
            10475
        ],
        "Skills": {
            "10471": "Skill_A_01",
            "10472": "Skill_S_Kazuha_01",
            "10475": "Skill_E_Kazuha_01"
        },
        "ProudMap": {
            "10471": 4731,
            "10472": 4732,
            "10475": 4739
        },
        "NameTextMapHash": 88505754,
        "SideIconName": "UI_AvatarIcon_Side_Kazuha",
        "QualityType": "QUALITY_ORANGE"
    },
    "10000048": {
        "Element": "Fire",
        "Consts": [
            "UI_Talent_S_Feiyan_01",
            "UI_Talent_S_Feiyan_02",
            "UI_Talent_U_Feiyan_01",
            "UI_Talent_S_Feiyan_03",
            "UI_Talent_U_Feiyan_02",
            "UI_Talent_S_Feiyan_04"
        ],
        "SkillOrder": [
            10481,
            10482,
            10485
        ],
        "Skills": {
            "10481": "Skill_A_Catalyst_MD",
            "10482": "Skill_S_Feiyan_01",
            "10485": "Skill_E_Feiyan_01"
        },
        "ProudMap": {
            "10481": 4831,
            "10482": 4832,
            "10485": 4839
        },
        "NameTextMapHash": 697277554,
        "SideIconName": "UI_AvatarIcon_Side_Feiyan",
        "QualityType": "QUALITY_PURPLE"
    },
    "10000049": {
        "Element": "Fire",
        "Consts": [
            "UI_Talent_S_Yoimiya_01",
            "UI_Talent_S_Yoimiya_02",
            "UI_Talent_U_Yoimiya_01",
            "UI_Talent_S_Yoimiya_03",
            "UI_Talent_U_Yoimiya_02",
            "UI_Talent_S_Yoimiya_04"
        ],
        "SkillOrder": [
            10491,
            10492,
            10495
        ],
        "Skills": {
            "10491": "Skill_A_02",
            "10492": "Skill_S_Yoimiya_01",
            "10495": "Skill_E_Yoimiya_01"
        },
        "ProudMap": {
            "10491": 4931,
            "10492": 4932,
            "10495": 4939
        },
        "NameTextMapHash": 2504399314,
        "SideIconName": "UI_AvatarIcon_Side_Yoimiya",
        "QualityType": "QUALITY_ORANGE"
    },
    "10000050": {
        "Element": "Fire",
        "Consts": [
            "UI_Talent_S_Tohma_01",
            "UI_Talent_S_Tohma_02",
            "UI_Talent_U_Tohma_01",
            "UI_Talent_S_Tohma_03",
            "UI_Talent_U_Tohma_02",
            "UI_Talent_S_Tohma_04"
        ],
        "SkillOrder": [
            10501,
            10502,
            10505
        ],
        "Skills": {
            "10501": "Skill_A_03",
            "10502": "Skill_S_Tohma_01",
            "10505": "Skill_E_Tohma_01"
        },
        "ProudMap": {
            "10501": 5031,
            "10502": 5032,
            "10505": 5039
        },
        "NameTextMapHash": 3555115602,
        "SideIconName": "UI_AvatarIcon_Side_Tohma",
        "QualityType": "QUALITY_PURPLE"
    },
    "10000051": {
        "Element": "Ice",
        "Consts": [
            "UI_Talent_S_Eula_02",
            "UI_Talent_S_Eula_01",
            "UI_Talent_U_Eula_01",
            "UI_Talent_S_Eula_03",
            "UI_Talent_U_Eula_02",
            "UI_Talent_S_Eula_04"
        ],
        "SkillOrder": [
            10511,
            10512,
            10515
        ],
        "Skills": {
            "10511": "Skill_A_04",
            "10512": "Skill_S_Eula_01",
            "10515": "Skill_E_Eula_01"
        },
        "ProudMap": {
            "10511": 5131,
            "10512": 5132,
            "10515": 5139
        },
        "NameTextMapHash": 3717667418,
        "SideIconName": "UI_AvatarIcon_Side_Eula",
        "QualityType": "QUALITY_ORANGE"
    },
    "10000052": {
        "Element": "Electric",
        "Consts": [
            "UI_Talent_S_Shougun_01",
            "UI_Talent_S_Shougun_03",
            "UI_Talent_U_Shougun_02",
            "UI_Talent_S_Shougun_02",
            "UI_Talent_U_Shougun_01",
            "UI_Talent_S_Shougun_04"
        ],
        "SkillOrder": [
            10521,
            10522,
            10525
        ],
        "Skills": {
            "10521": "Skill_A_03",
            "10522": "Skill_S_Shougun_01",
            "10525": "Skill_E_Shougun_01"
        },
        "ProudMap": {
            "10521": 5231,
            "10522": 5232,
            "10525": 5239
        },
        "NameTextMapHash": 3024507506,
        "SideIconName": "UI_AvatarIcon_Side_Shougun",
        "QualityType": "QUALITY_ORANGE"
    },
    "10000053": {
        "Element": "Wind",
        "Consts": [
            "UI_Talent_S_Sayu_01",
            "UI_Talent_S_Sayu_02",
            "UI_Talent_U_Sayu_02",
            "UI_Talent_S_Sayu_03",
            "UI_Talent_U_Sayu_01",
            "UI_Talent_S_Sayu_04"
        ],
        "SkillOrder": [
            10531,
            10532,
            10535
        ],
        "Skills": {
            "10531": "Skill_A_04",
            "10532": "Skill_S_Sayu_01",
            "10535": "Skill_E_Sayu_01"
        },
        "ProudMap": {
            "10531": 5331,
            "10532": 5332,
            "10535": 5339
        },
        "NameTextMapHash": 2388785242,
        "SideIconName": "UI_AvatarIcon_Side_Sayu",
        "QualityType": "QUALITY_PURPLE"
    },
    "10000054": {
        "Element": "Water",
        "Consts": [
            "UI_Talent_S_Kokomi_01",
            "UI_Talent_S_Kokomi_02",
            "UI_Talent_U_Kokomi_02",
            "UI_Talent_S_Kokomi_03",
            "UI_Talent_U_Kokomi_01",
            "UI_Talent_S_Kokomi_04"
        ],
        "SkillOrder": [
            10541,
            10542,
            10545
        ],
        "Skills": {
            "10541": "Skill_A_Catalyst_MD",
            "10542": "Skill_S_Kokomi_01",
            "10545": "Skill_E_Kokomi_01"
        },
        "ProudMap": {
            "10541": 5431,
            "10542": 5432,
            "10545": 5439
        },
        "NameTextMapHash": 3914045794,
        "SideIconName": "UI_AvatarIcon_Side_Kokomi",
        "QualityType": "QUALITY_ORANGE"
    },
    "10000055": {
        "Element": "Rock",
        "Consts": [
            "UI_Talent_S_Gorou_01",
            "UI_Talent_S_Gorou_02",
            "UI_Talent_U_Gorou_01",
            "UI_Talent_S_Gorou_03",
            "UI_Talent_U_Gorou_02",
            "UI_Talent_S_Gorou_04"
        ],
        "SkillOrder": [
            10551,
            10552,
            10555
        ],
        "Skills": {
            "10551": "Skill_A_02",
            "10552": "Skill_S_Gorou_01",
            "10555": "Skill_E_Gorou_01"
        },
        "ProudMap": {
            "10551": 5531,
            "10552": 5532,
            "10555": 5539
        },
        "NameTextMapHash": 3400133546,
        "SideIconName": "UI_AvatarIcon_Side_Gorou",
        "QualityType": "QUALITY_PURPLE"
    },
    "10000056": {
        "Element": "Electric",
        "Consts": [
            "UI_Talent_S_Sara_05",
            "UI_Talent_S_Sara_02",
            "UI_Talent_U_Sara_02",
            "UI_Talent_S_Sara_03",
            "UI_Talent_U_Sara_01",
            "UI_Talent_S_Sara_04"
        ],
        "SkillOrder": [
            10561,
            10562,
            10565
        ],
        "Skills": {
            "10561": "Skill_A_02",
            "10562": "Skill_S_Sara_01",
            "10565": "Skill_E_Sara_01"
        },
        "ProudMap": {
            "10561": 5631,
            "10562": 5632,
            "10565": 5639
        },
        "NameTextMapHash": 1483922610,
        "SideIconName": "UI_AvatarIcon_Side_Sara",
        "QualityType": "QUALITY_PURPLE"
    },
    "10000057": {
        "Element": "Rock",
        "Consts": [
            "UI_Talent_S_Itto_01",
            "UI_Talent_S_Itto_02",
            "UI_Talent_U_Itto_01",
            "UI_Talent_S_Itto_03",
            "UI_Talent_U_Itto_02",
            "UI_Talent_S_Itto_04"
        ],
        "SkillOrder": [
            10571,
            10572,
            10575
        ],
        "Skills": {
            "10571": "Skill_A_04",
            "10572": "Skill_S_Itto_01",
            "10575": "Skill_E_Itto_01"
        },
        "ProudMap": {
            "10571": 5731,
            "10572": 5732,
            "10575": 5739
        },
        "NameTextMapHash": 3068316954,
        "SideIconName": "UI_AvatarIcon_Side_Itto",
        "QualityType": "QUALITY_ORANGE"
    },
    "10000058": {
        "Element": "Electric",
        "Consts": [
            "UI_Talent_S_Yae_01",
            "UI_Talent_S_Yae_02",
            "UI_Talent_U_Yae_01",
            "UI_Talent_S_Yae_03",
            "UI_Talent_U_Yae_02",
            "UI_Talent_S_Yae_04"
        ],
        "SkillOrder": [
            10581,
            10582,
            10585
        ],
        "Skills": {
            "10581": "Skill_A_Catalyst_MD",
            "10582": "Skill_S_Yae_01",
            "10585": "Skill_E_Yae_01"
        },
        "ProudMap": {
            "10581": 5831,
            "10582": 5832,
            "10585": 5839
        },
        "NameTextMapHash": 2713453234,
        "SideIconName": "UI_AvatarIcon_Side_Yae",
        "QualityType": "QUALITY_ORANGE"
    },
    "10000059": {
        "Element": "Wind",
        "Consts": [
            "UI_Talent_S_Heizo_01",
            "UI_Talent_S_Heizo_02",
            "UI_Talent_U_Heizo_01",
            "UI_Talent_S_Heizo_03",
            "UI_Talent_U_Heizo_02",
            "UI_Talent_S_Heizo_04"
        ],
        "SkillOrder": [
            10591,
            10592,
            10595
        ],
        "Skills": {
            "10591": "Skill_A_Catalyst_MD",
            "10592": "Skill_S_Heizo_01",
            "10595": "Skill_E_Heizo_01"
        },
        "ProudMap": {
            "10591": 5931,
            "10592": 5932,
            "10595": 5939
        },
        "NameTextMapHash": 646032090,
        "SideIconName": "UI_AvatarIcon_Side_Heizo",
        "QualityType": "QUALITY_PURPLE"
    },
    "10000060": {
        "Element": "Water",
        "Consts": [
            "UI_Talent_S_Yelan_01",
            "UI_Talent_S_Yelan_02",
            "UI_Talent_U_Yelan_01",
            "UI_Talent_S_Yelan_03",
            "UI_Talent_U_Yelan_02",
            "UI_Talent_S_Yelan_04"
        ],
        "SkillOrder": [
            10606,
            10607,
            10610
        ],
        "Skills": {
            "10606": "Skill_A_02",
            "10607": "Skill_S_Yelan_01",
            "10610": "Skill_E_Yelan_01"
        },
        "ProudMap": {
            "10606": 6031,
            "10607": 6032,
            "10610": 6039
        },
        "NameTextMapHash": 2848374378,
        "SideIconName": "UI_AvatarIcon_Side_Yelan",
        "QualityType": "QUALITY_ORANGE"
    },
    "10000062": {
        "Element": "Ice",
        "Consts": [
            "UI_Talent_S_Aloy_Lock",
            "UI_Talent_S_Aloy_Lock",
            "UI_Talent_S_Aloy_Lock",
            "UI_Talent_S_Aloy_Lock",
            "UI_Talent_S_Aloy_Lock",
            "UI_Talent_S_Aloy_Lock"
        ],
        "SkillOrder": [
            10621,
            10622,
            10625
        ],
        "Skills": {
            "10621": "Skill_A_02",
            "10622": "Skill_S_Aloy_01",
            "10625": "Skill_E_Aloy_01"
        },
        "ProudMap": {
            "10621": 6231,
            "10622": 6232,
            "10625": 6239
        },
        "NameTextMapHash": 3689108098,
        "SideIconName": "UI_AvatarIcon_Side_Aloy",
        "QualityType": "QUALITY_ORANGE_SP"
    },
    "10000063": {
        "Element": "Ice",
        "Consts": [
            "UI_Talent_S_Shenhe_02",
            "UI_Talent_S_Shenhe_01",
            "UI_Talent_U_Shenhe_01",
            "UI_Talent_S_Shenhe_03",
            "UI_Talent_U_Shenhe_02",
            "UI_Talent_S_Shenhe_04"
        ],
        "SkillOrder": [
            10631,
            10632,
            10635
        ],
        "Skills": {
            "10631": "Skill_A_03",
            "10632": "Skill_S_Shenhe_01",
            "10635": "Skill_E_Shenhe_01"
        },
        "ProudMap": {
            "10631": 6331,
            "10632": 6332,
            "10635": 6339
        },
        "NameTextMapHash": 334242634,
        "SideIconName": "UI_AvatarIcon_Side_Shenhe",
        "QualityType": "QUALITY_ORANGE"
    },
    "10000064": {
        "Element": "Rock",
        "Consts": [
            "UI_Talent_S_Yunjin_01",
            "UI_Talent_S_Yunjin_02",
            "UI_Talent_U_Yunjin_01",
            "UI_Talent_S_Yunjin_03",
            "UI_Talent_U_Yunjin_02",
            "UI_Talent_S_Yunjin_04"
        ],
        "SkillOrder": [
            10641,
            10642,
            10643
        ],
        "Skills": {
            "10641": "Skill_A_03",
            "10642": "Skill_S_Yunjin_01",
            "10643": "Skill_E_Yunjin_01"
        },
        "ProudMap": {
            "10641": 6431,
            "10642": 6432,
            "10643": 6439
        },
        "NameTextMapHash": 655825874,
        "SideIconName": "UI_AvatarIcon_Side_Yunjin",
        "QualityType": "QUALITY_PURPLE"
    },
    "10000065": {
        "Element": "Electric",
        "Consts": [
            "UI_Talent_S_Shinobu_01",
            "UI_Talent_S_Shinobu_02",
            "UI_Talent_U_Shinobu_01",
            "UI_Talent_S_Shinobu_03",
            "UI_Talent_U_Shinobu_02",
            "UI_Talent_S_Shinobu_04"
        ],
        "SkillOrder": [
            10651,
            10652,
            10655
        ],
        "Skills": {
            "10651": "Skill_A_01",
            "10652": "Skill_S_Shinobu_01",
            "10655": "Skill_E_Shinobu_01"
        },
        "ProudMap": {
            "10651": 6531,
            "10652": 6532,
            "10655": 6539
        },
        "NameTextMapHash": 1940821986,
        "SideIconName": "UI_AvatarIcon_Side_Shinobu",
        "QualityType": "QUALITY_PURPLE"
    },
    "10000066": {
        "Element": "Water",
        "Consts": [
            "UI_Talent_S_Ayato_01",
            "UI_Talent_S_Ayato_02",
            "UI_Talent_U_Ayato_02",
            "UI_Talent_S_Ayato_03",
            "UI_Talent_U_Ayato_01",
            "UI_Talent_S_Ayato_04"
        ],
        "SkillOrder": [
            10661,
            10662,
            10665
        ],
        "Skills": {
            "10661": "Skill_A_01",
            "10662": "Skill_S_Ayato_01",
            "10665": "Skill_E_Ayato_01"
        },
        "ProudMap": {
            "10661": 6631,
            "10662": 6632,
            "10665": 6639
        },
        "NameTextMapHash": 1588620330,
        "SideIconName": "UI_AvatarIcon_Side_Ayato",
        "QualityType": "QUALITY_ORANGE"
    },
    "10000005-501": {},
    "10000005-502": {},
    "10000005-503": {},
    "10000005-504": {
        "Element": "Wind",
        "Consts": [
            "UI_Talent_S_PlayerWind_01",
            "UI_Talent_S_PlayerWind_02",
            "UI_Talent_U_PlayerWind_02",
            "UI_Talent_S_PlayerWind_03",
            "UI_Talent_U_PlayerWind_01",
            "UI_Talent_S_PlayerWind_04"
        ],
        "SkillOrder": [
            100543,
            10067,
            10068
        ],
        "Skills": {
            "10067": "Skill_S_PlayerWind_01",
            "10068": "Skill_E_PlayerWind_01",
            "100543": "Skill_A_01"
        },
        "NameTextMapHash": 1533656818,
        "ProudMap": {
            "10067": 732,
            "10068": 739,
            "100543": 730
        },
        "SideIconName": "UI_AvatarIcon_Side_PlayerBoy",
        "QualityType": "QUALITY_ORANGE"
    },
    "10000005-505": {},
    "10000005-506": {
        "Element": "Rock",
        "Consts": [
            "UI_Talent_S_PlayerRock_01",
            "UI_Talent_S_PlayerRock_02",
            "UI_Talent_U_PlayerRock_02",
            "UI_Talent_S_PlayerRock_03",
            "UI_Talent_U_PlayerRock_01",
            "UI_Talent_S_PlayerRock_04"
        ],
        "SkillOrder": [
            100545,
            10077,
            10078
        ],
        "Skills": {
            "10077": "Skill_S_PlayerRock_01",
            "10078": "Skill_E_PlayerRock_01",
            "100545": "Skill_A_01"
        },
        "NameTextMapHash": 1533656818,
        "ProudMap": {
            "10077": 932,
            "10078": 939,
            "100545": 730
        },
        "SideIconName": "UI_AvatarIcon_Side_PlayerBoy",
        "QualityType": "QUALITY_ORANGE"
    },
    "10000005-507": {
        "Element": "Electric",
        "Consts": [
            "UI_Talent_S_PlayerElectric_01",
            "UI_Talent_S_PlayerElectric_02",
            "UI_Talent_U_PlayerElectric_01",
            "UI_Talent_S_PlayerElectric_03",
            "UI_Talent_U_PlayerElectric_02",
            "UI_Talent_S_PlayerElectric_04"
        ],
        "SkillOrder": [
            100546,
            10602,
            10605
        ],
        "Skills": {
            "10602": "Skill_S_PlayerElectric_01",
            "10605": "Skill_E_PlayerElectric_01",
            "100546": "Skill_A_01"
        },
        "NameTextMapHash": 1533656818,
        "ProudMap": {
            "10602": 1032,
            "10605": 1039,
            "100546": 1030
        },
        "SideIconName": "UI_AvatarIcon_Side_PlayerBoy",
        "QualityType": "QUALITY_ORANGE"
    },
    "10000007-701": {},
    "10000007-702": {},
    "10000007-703": {},
    "10000007-704": {
        "Element": "Wind",
        "Consts": [
            "UI_Talent_S_PlayerWind_01",
            "UI_Talent_S_PlayerWind_02",
            "UI_Talent_U_PlayerWind_02",
            "UI_Talent_S_PlayerWind_03",
            "UI_Talent_U_PlayerWind_01",
            "UI_Talent_S_PlayerWind_04"
        ],
        "SkillOrder": [
            100553,
            10067,
            10068
        ],
        "Skills": {
            "10067": "Skill_S_PlayerWind_01",
            "10068": "Skill_E_PlayerWind_01",
            "100553": "Skill_A_01"
        },
        "NameTextMapHash": 3816664530,
        "ProudMap": {
            "10067": 732,
            "10068": 739,
            "100553": 731
        },
        "SideIconName": "UI_AvatarIcon_Side_PlayerGirl",
        "QualityType": "QUALITY_ORANGE"
    },
    "10000007-705": {},
    "10000007-706": {
        "Element": "Rock",
        "Consts": [
            "UI_Talent_S_PlayerRock_01",
            "UI_Talent_S_PlayerRock_02",
            "UI_Talent_U_PlayerRock_02",
            "UI_Talent_S_PlayerRock_03",
            "UI_Talent_U_PlayerRock_01",
            "UI_Talent_S_PlayerRock_04"
        ],
        "SkillOrder": [
            100555,
            10077,
            10078
        ],
        "Skills": {
            "10077": "Skill_S_PlayerRock_01",
            "10078": "Skill_E_PlayerRock_01",
            "100555": "Skill_A_01"
        },
        "NameTextMapHash": 3816664530,
        "ProudMap": {
            "10077": 932,
            "10078": 939,
            "100555": 731
        },
        "SideIconName": "UI_AvatarIcon_Side_PlayerGirl",
        "QualityType": "QUALITY_ORANGE"
    },
    "10000007-707": {
        "Element": "Electric",
        "Consts": [
            "UI_Talent_S_PlayerElectric_01",
            "UI_Talent_S_PlayerElectric_02",
            "UI_Talent_U_PlayerElectric_01",
            "UI_Talent_S_PlayerElectric_03",
            "UI_Talent_U_PlayerElectric_02",
            "UI_Talent_S_PlayerElectric_04"
        ],
        "SkillOrder": [
            100556,
            10602,
            10605
        ],
        "Skills": {
            "10602": "Skill_S_PlayerElectric_01",
            "10605": "Skill_E_PlayerElectric_01",
            "100556": "Skill_A_01"
        },
        "NameTextMapHash": 3816664530,
        "ProudMap": {
            "10602": 1032,
            "10605": 1039,
            "100556": 1031
        },
        "SideIconName": "UI_AvatarIcon_Side_PlayerGirl",
        "QualityType": "QUALITY_ORANGE"
    }
}
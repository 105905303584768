{
    "advanced": {
        "action_list": "Action List",
        "helpers": "Helpers",
        "hide": "Hide",
        "loading_workers": "Loading workers",
        "run": "Run",
        "show": "Show",
        "sim_options": "Sim Options",
        "substat_helper": "Substat Helper",
        "workers_available": "Workers available: "
    },
    "character": {
        "artifact_stats": "Artifact Stats",
        "c_post": "",
        "c_pre": "C",
        "lvl": "Lvl",
        "talents": "Talents"
    },
    "characteredit": {
        "add_set_bonus": "Add Set Bonus",
        "anemo": "Anemo%",
        "artifact_stats_main": "Artifact Stats (Main + Sub)",
        "ascension": "Ascension",
        "atk_atk": "Atk/Atk%",
        "attack": "Attack",
        "burst": "Burst",
        "cd": "CD",
        "change": "Change",
        "character": "Character",
        "cons": "Cons",
        "cr": "CR",
        "cryo": "Cryo%",
        "def_def": "Def/Def%",
        "electro": "Electro%",
        "em": "EM",
        "er": "ER",
        "geo": "Geo%",
        "heal": "Heal%",
        "hp_hp": "HP/HP%",
        "hydro": "Hydro%",
        "level": "Level",
        "phy": "Phy%",
        "pyro": "Pyro%",
        "refine": "Refine",
        "set_bonus": "Set Bonus:",
        "skill": "Skill",
        "weapons_and_artifacts": "Weapons and Artifacts"
    },
    "components": {
        "close": "Close",
        "currently_loaded_workers": "Currently loaded workers: ",
        "no_characters": "No characters",
        "result_post": "ms with average DPS: ",
        "result_pre": "Simulation completed in ",
        "running_simulation": "Running Simulation:",
        "see_results_in": "See Results in Viewer",
        "set": "Set",
        "simulation_exited_with": "Simulation exited with error: ",
        "workers_post": " workers",
        "workers_pre": "Running sim with "
    },
    "dash": {
        "about": "About",
        "advanced_mode": "Advanced Mode",
        "contribute": "Contribute",
        "desktop_tool": "Desktop Tool",
        "documentation": "Documentation",
        "is_a_team": "is a team DPS simulator for Genshin Impact. Get started by choosing one of the following options!",
        "simulator": "Simulator",
        "teams_db": "Teams DB",
        "viewer": "Viewer"
    },
    "db": {
        "author": "Author: ",
        "average_dps_per": "Average DPS per target: ",
        "c_post": "",
        "c_pre": "C",
        "cancel": "Cancel",
        "character": "Character",
        "continue": "Continue",
        "description": "Description:",
        "details": "Details",
        "er": "ER: ",
        "error_loading_database": "Error loading database. No data found",
        "filters": "Filters:",
        "hash": "Hash: ",
        "load_in_simulator": "Load in Simulator",
        "loading": "Loading ...",
        "number_of_targets": "Number of targets: ",
        "r": " R",
        "show_in_viewer": "Show in Viewer",
        "total_dps": "Total DPS: ",
        "type_to_search": "Type to search...",
        "weapon": "Weapon"
    },
    "elements": {
        "none": "None",
        "anemo": "Anemo",
        "geo": "Geo",
        "pyro": "Pyro",
        "cryo": "Cryo",
        "hydro": "Hydro",
        "electro": "Electro",
        "dendro": "Dendro"
    },
    "footer": {
        "gcsim_is_not": "gcsim is not affiliated with miHoYo. Genshin Impact, game content and materials are trademarks and copyrights of miHoYo."
    },
    "importer": {
        "import_success": "GOOD data imported successfully"
    },
    "links": {
        "error_loading_database": "Error loading database. No data found"
    },
    "nav": {
        "about": "About",
        "advanced": "Advanced",
        "chinese": "中文",
        "japanese": "日本語",
        "english": "English",
        "spanish": "Español",
        "russian": "Русский",
        "german": "Deutsch",
        "language": "Language:",
        "simulator": "Simulator",
        "teams_db": "Teams DB",
        "viewer": "Viewer"
    },
    "sim": {
        "loading_simulator_please": "Loading simulator. Please wait"
    },
    "simple": {
        "action_list": "Action List",
        "add_character": "Add Character",
        "discord_post": " if you need some ideas.",
        "discord_pre": "Enter the action list you wish to use in the textbox below. We have a vast collection of user submitted action lists on our ",
        "documentation": "documentation",
        "documentation_post": " which includes a simple tutorial on how to write a config from scratch.",
        "documentation_pre": "Alternatively, check out our ",
        "done": "Done",
        "exit_edit": "Exit edit",
        "give_me_back": "Give me back my tooltips!",
        "hide": "Hide",
        "hide_all_tips": "Hide all tips",
        "loading_workers": "Loading workers",
        "run": "Run",
        "show": "Show",
        "sim_options": "Sim Options",
        "start_by_adding": "Start by adding some team members",
        "team": "Team",
        "video": "video",
        "video_post": " to get started.",
        "video_pre": "Get started by adding characters in to your team. Check out this ",
        "workers_available": "Workers available: "
    },
    "src": {
        "this_page_is": "This page is not implemented yet. Stay tuned!"
    },
    "viewer": {
        "ability_usage_count": "Ability Usage Count (Average)",
        "api_key_supporters": "API Key (Supporters only)",
        "attack": "Attack",
        "average_character_field": "Average Character Field Time",
        "average_dps_by": "Average DPS By Character",
        "avg_dps": "Avg DPS",
        "built_on": ", built on: ",
        "cancel": "Cancel",
        "cd": "CD",
        "clear": "Clear",
        "close": "Close",
        "combined": "Combined",
        "config": "Config",
        "continue": "Continue",
        "copied_to_clipboard": "Config copied to clipboard",
        "copy": "Copy Config",
        "copy_link": "Copy Link",
        "cr": "CR",
        "create_a_shareable": "Create a shareable link",
        "damage_dealt_over": "Damage Dealt Over Time",
        "debug": "Debug",
        "debug_settings": "Debug Settings",
        "defaults": "Defaults",
        "defense": "Defense",
        "details": "Details",
        "dps_by_ability": "DPS by Ability (Average)",
        "em": "EM",
        "er": "ER",
        "error_copying_not": "Error copying :( Not sure what went wrong",
        "error_encountered": "error encountered: ",
        "git_hash": "git hash: ",
        "hp": "HP",
        "level": "Level",
        "link_is_permanent": "Link is permanent. ",
        "link_post": "Results can be viewed at: ",
        "link_pre": "Upload successful. ",
        "link_will_expire": "Link will expire in 7 days. ",
        "load_this_configuration": "Load this configuration in the simulator.",
        "log_options": "Log Options",
        "make_link_permanent": "Make link permanent?",
        "note_that_by": "Note that by default shareable links are only valid for 7 days. This is done in order to keep server storage usage at a reasonable level. Contributors and Ko-Fi supporters can enter in a private key to make their links permanent.",
        "particles_count": "Particles Count",
        "please_note_that": "Please note that this is not an attempt to get people to donate money. This is a simple way to gate the amount of data being shared while providing a small thank you for those that either supported this project financially or by contributing. If you do need a permanent link and you don't have the access key, simply hop over to our Discord and ask someone to do it for you.",
        "reactions_triggered": "Reactions Triggered",
        "refinement": "Refinement",
        "sec_post": "s of combat (",
        "sec_pre": "Simulated ",
        "send_to_simulator": "Send To Simulator",
        "share": "Share",
        "substats": "Substats",
        "summary": "Summary",
        "target": "Target",
        "this_will_overwrite": "This will overwrite any existing configuration you may have. Are you sure you wish to continue?",
        "time_post": "s to run).",
        "time_pre": " iterations took ",
        "unknown": "unknown",
        "upload": "Upload",
        "upload_failed": "upload failed"
    },
    "viewerdashboard": {
        "drag_n_drop": "Drag 'n' drop gzipped JSON file from gcsim here, or click to select",
        "drop_the_file": "Drop the file here ...",
        "error_retrieving_specified": "error retrieving specified URL",
        "or_select_from": "Or select from the following previously opened files (deleted when page reloads):",
        "upload_a_file": "Upload a file",
        "url_does_not": "URL does not contain valid gzipped JSON file"
    }
}
{
  "1": {
    "hp": [
      129,
      178,
      227,
      275,
      324
    ],
    "hp_": [
      0.031,
      0.043,
      0.055,
      0.067,
      0.079
    ],
    "atk": [
      8,
      12,
      15,
      18,
      21
    ],
    "atk_": [
      0.031,
      0.043,
      0.055,
      0.067,
      0.079
    ],
    "def_": [
      0.039,
      0.054,
      0.069,
      0.084,
      0.099
    ],
    "critRate_": [
      0.021,
      0.029,
      0.037,
      0.045,
      0.053
    ],
    "critDMG_": [
      0.042,
      0.058,
      0.074,
      0.09,
      0.105
    ],
    "eleMas": [
      12.6,
      17.3,
      22.1,
      26.9,
      31.6
    ],
    "enerRech_": [
      0.035,
      0.048,
      0.061,
      0.075,
      0.088
    ],
    "heal_": [
      0.024,
      0.033,
      0.043,
      0.052,
      0.061
    ],
    "physical_dmg_": [
      0.039,
      0.054,
      0.069,
      0.084,
      0.099
    ],
    "electro_dmg_": [
      0.031,
      0.043,
      0.055,
      0.067,
      0.079
    ],
    "geo_dmg_": [
      0.031,
      0.043,
      0.055,
      0.067,
      0.079
    ],
    "pyro_dmg_": [
      0.031,
      0.043,
      0.055,
      0.067,
      0.079
    ],
    "hydro_dmg_": [
      0.031,
      0.043,
      0.055,
      0.067,
      0.079
    ],
    "cryo_dmg_": [
      0.031,
      0.043,
      0.055,
      0.067,
      0.079
    ],
    "anemo_dmg_": [
      0.031,
      0.043,
      0.055,
      0.067,
      0.079
    ]
  },
  "2": {
    "hp": [
      258,
      331,
      404,
      478,
      551,
      624,
      697,
      770,
      843
    ],
    "hp_": [
      0.042,
      0.054,
      0.066,
      0.078,
      0.09,
      0.101,
      0.113,
      0.125,
      0.137
    ],
    "atk": [
      17,
      22,
      26,
      31,
      36,
      41,
      45,
      50,
      55
    ],
    "atk_": [
      0.042,
      0.054,
      0.066,
      0.078,
      0.09,
      0.101,
      0.113,
      0.125,
      0.137
    ],
    "def_": [
      0.052,
      0.067,
      0.082,
      0.097,
      0.112,
      0.127,
      0.142,
      0.156,
      0.171
    ],
    "critRate_": [
      0.028,
      0.036,
      0.044,
      0.052,
      0.06,
      0.068,
      0.076,
      0.083,
      0.091
    ],
    "critDMG_": [
      0.056,
      0.072,
      0.088,
      0.104,
      0.119,
      0.135,
      0.151,
      0.167,
      0.183
    ],
    "eleMas": [
      16.8,
      21.5,
      26.3,
      31.1,
      35.8,
      40.6,
      45.3,
      50.1,
      54.8
    ],
    "enerRech_": [
      0.047,
      0.06,
      0.073,
      0.086,
      0.099,
      0.113,
      0.126,
      0.139,
      0.152
    ],
    "heal_": [
      0.032,
      0.041,
      0.051,
      0.06,
      0.069,
      0.078,
      0.087,
      0.096,
      0.105
    ],
    "physical_dmg_": [
      0.052,
      0.067,
      0.082,
      0.097,
      0.112,
      0.127,
      0.142,
      0.156,
      0.171
    ],
    "electro_dmg_": [
      0.042,
      0.054,
      0.066,
      0.078,
      0.09,
      0.101,
      0.113,
      0.125,
      0.137
    ],
    "geo_dmg_": [
      0.042,
      0.054,
      0.066,
      0.078,
      0.09,
      0.101,
      0.113,
      0.125,
      0.137
    ],
    "pyro_dmg_": [
      0.042,
      0.054,
      0.066,
      0.078,
      0.09,
      0.101,
      0.113,
      0.125,
      0.137
    ],
    "hydro_dmg_": [
      0.042,
      0.054,
      0.066,
      0.078,
      0.09,
      0.101,
      0.113,
      0.125,
      0.137
    ],
    "cryo_dmg_": [
      0.042,
      0.054,
      0.066,
      0.078,
      0.09,
      0.101,
      0.113,
      0.125,
      0.137
    ],
    "anemo_dmg_": [
      0.042,
      0.054,
      0.066,
      0.078,
      0.09,
      0.101,
      0.113,
      0.125,
      0.137
    ]
  },
  "3": {
    "hp": [
      430,
      552,
      674,
      796,
      918,
      1040,
      1162,
      1283,
      1405,
      1527,
      1649,
      1771,
      1893
    ],
    "hp_": [
      0.052,
      0.067,
      0.082,
      0.097,
      0.112,
      0.127,
      0.142,
      0.156,
      0.171,
      0.186,
      0.201,
      0.216,
      0.231
    ],
    "atk": [
      28,
      36,
      44,
      52,
      60,
      68,
      76,
      84,
      91,
      99,
      107,
      115,
      123
    ],
    "atk_": [
      0.052,
      0.067,
      0.082,
      0.097,
      0.112,
      0.127,
      0.142,
      0.156,
      0.171,
      0.186,
      0.201,
      0.216,
      0.231
    ],
    "def_": [
      0.066,
      0.084,
      0.103,
      0.121,
      0.14,
      0.158,
      0.177,
      0.196,
      0.214,
      0.233,
      0.251,
      0.27,
      0.288
    ],
    "critRate_": [
      0.035,
      0.045,
      0.055,
      0.065,
      0.075,
      0.084,
      0.094,
      0.104,
      0.114,
      0.124,
      0.134,
      0.144,
      0.154
    ],
    "critDMG_": [
      0.07,
      0.09,
      0.11,
      0.129,
      0.149,
      0.169,
      0.189,
      0.209,
      0.228,
      0.248,
      0.268,
      0.288,
      0.308
    ],
    "eleMas": [
      21,
      26.9,
      32.9,
      38.8,
      44.8,
      50.7,
      56.7,
      62.6,
      68.5,
      74.5,
      80.4,
      86.4,
      92.3
    ],
    "enerRech_": [
      0.058,
      0.075,
      0.091,
      0.108,
      0.124,
      0.141,
      0.157,
      0.174,
      0.19,
      0.207,
      0.223,
      0.24,
      0.256
    ],
    "heal_": [
      0.04,
      0.052,
      0.063,
      0.075,
      0.086,
      0.098,
      0.109,
      0.12,
      0.132,
      0.143,
      0.155,
      0.166,
      0.178
    ],
    "physical_dmg_": [
      0.066,
      0.084,
      0.103,
      0.121,
      0.14,
      0.158,
      0.177,
      0.196,
      0.214,
      0.233,
      0.251,
      0.27,
      0.288
    ],
    "electro_dmg_": [
      0.052,
      0.067,
      0.082,
      0.097,
      0.112,
      0.127,
      0.142,
      0.156,
      0.171,
      0.186,
      0.201,
      0.216,
      0.231
    ],
    "geo_dmg_": [
      0.052,
      0.067,
      0.082,
      0.097,
      0.112,
      0.127,
      0.142,
      0.156,
      0.171,
      0.186,
      0.201,
      0.216,
      0.231
    ],
    "pyro_dmg_": [
      0.052,
      0.067,
      0.082,
      0.097,
      0.112,
      0.127,
      0.142,
      0.156,
      0.171,
      0.186,
      0.201,
      0.216,
      0.231
    ],
    "hydro_dmg_": [
      0.052,
      0.067,
      0.082,
      0.097,
      0.112,
      0.127,
      0.142,
      0.156,
      0.171,
      0.186,
      0.201,
      0.216,
      0.231
    ],
    "cryo_dmg_": [
      0.052,
      0.067,
      0.082,
      0.097,
      0.112,
      0.127,
      0.142,
      0.156,
      0.171,
      0.186,
      0.201,
      0.216,
      0.231
    ],
    "anemo_dmg_": [
      0.052,
      0.067,
      0.082,
      0.097,
      0.112,
      0.127,
      0.142,
      0.156,
      0.171,
      0.186,
      0.201,
      0.216,
      0.231
    ]
  },
  "4": {
    "hp": [
      645,
      828,
      1011,
      1194,
      1377,
      1559,
      1742,
      1925,
      2108,
      2291,
      2474,
      2657,
      2839,
      3022,
      3205,
      3388,
      3571
    ],
    "hp_": [
      0.063,
      0.081,
      0.099,
      0.116,
      0.134,
      0.152,
      0.17,
      0.188,
      0.206,
      0.223,
      0.241,
      0.259,
      0.277,
      0.295,
      0.313,
      0.33,
      0.348
    ],
    "atk": [
      42,
      54,
      66,
      78,
      90,
      102,
      113,
      125,
      137,
      149,
      161,
      173,
      185,
      197,
      209,
      221,
      232
    ],
    "atk_": [
      0.063,
      0.081,
      0.099,
      0.116,
      0.134,
      0.152,
      0.17,
      0.188,
      0.206,
      0.223,
      0.241,
      0.259,
      0.277,
      0.295,
      0.313,
      0.33,
      0.348
    ],
    "def_": [
      0.079,
      0.101,
      0.123,
      0.146,
      0.168,
      0.19,
      0.212,
      0.235,
      0.257,
      0.279,
      0.302,
      0.324,
      0.346,
      0.368,
      0.391,
      0.413,
      0.435
    ],
    "critRate_": [
      0.042,
      0.054,
      0.066,
      0.078,
      0.09,
      0.101,
      0.113,
      0.125,
      0.137,
      0.149,
      0.161,
      0.173,
      0.185,
      0.197,
      0.208,
      0.22,
      0.232
    ],
    "critDMG_": [
      0.084,
      0.108,
      0.131,
      0.155,
      0.179,
      0.203,
      0.227,
      0.25,
      0.274,
      0.298,
      0.322,
      0.345,
      0.369,
      0.393,
      0.417,
      0.441,
      0.464
    ],
    "eleMas": [
      25.2,
      32.3,
      39.4,
      46.6,
      53.7,
      60.8,
      68,
      75.1,
      82.2,
      89.4,
      96.5,
      103.6,
      110.8,
      117.9,
      125,
      132.2,
      139.3
    ],
    "enerRech_": [
      0.07,
      0.09,
      0.11,
      0.129,
      0.149,
      0.169,
      0.189,
      0.209,
      0.228,
      0.248,
      0.268,
      0.288,
      0.308,
      0.328,
      0.347,
      0.367,
      0.387
    ],
    "heal_": [
      0.048,
      0.062,
      0.076,
      0.09,
      0.103,
      0.117,
      0.131,
      0.144,
      0.158,
      0.172,
      0.186,
      0.199,
      0.213,
      0.227,
      0.24,
      0.254,
      0.268
    ],
    "physical_dmg_": [
      0.079,
      0.101,
      0.123,
      0.146,
      0.168,
      0.19,
      0.212,
      0.235,
      0.257,
      0.279,
      0.302,
      0.324,
      0.346,
      0.368,
      0.391,
      0.413,
      0.435
    ],
    "electro_dmg_": [
      0.063,
      0.081,
      0.099,
      0.116,
      0.134,
      0.152,
      0.17,
      0.188,
      0.206,
      0.223,
      0.241,
      0.259,
      0.277,
      0.295,
      0.313,
      0.33,
      0.348
    ],
    "geo_dmg_": [
      0.063,
      0.081,
      0.099,
      0.116,
      0.134,
      0.152,
      0.17,
      0.188,
      0.206,
      0.223,
      0.241,
      0.259,
      0.277,
      0.295,
      0.313,
      0.33,
      0.348
    ],
    "pyro_dmg_": [
      0.063,
      0.081,
      0.099,
      0.116,
      0.134,
      0.152,
      0.17,
      0.188,
      0.206,
      0.223,
      0.241,
      0.259,
      0.277,
      0.295,
      0.313,
      0.33,
      0.348
    ],
    "hydro_dmg_": [
      0.063,
      0.081,
      0.099,
      0.116,
      0.134,
      0.152,
      0.17,
      0.188,
      0.206,
      0.223,
      0.241,
      0.259,
      0.277,
      0.295,
      0.313,
      0.33,
      0.348
    ],
    "cryo_dmg_": [
      0.063,
      0.081,
      0.099,
      0.116,
      0.134,
      0.152,
      0.17,
      0.188,
      0.206,
      0.223,
      0.241,
      0.259,
      0.277,
      0.295,
      0.313,
      0.33,
      0.348
    ],
    "anemo_dmg_": [
      0.063,
      0.081,
      0.099,
      0.116,
      0.134,
      0.152,
      0.17,
      0.188,
      0.206,
      0.223,
      0.241,
      0.259,
      0.277,
      0.295,
      0.313,
      0.33,
      0.348
    ]
  },
  "5": {
    "hp": [
      717,
      920,
      1123,
      1326,
      1530,
      1733,
      1936,
      2139,
      2342,
      2545,
      2749,
      2952,
      3155,
      3358,
      3561,
      3764,
      3967,
      4171,
      4374,
      4577,
      4780
    ],
    "hp_": [
      0.07,
      0.09,
      0.11,
      0.129,
      0.149,
      0.169,
      0.189,
      0.209,
      0.228,
      0.248,
      0.268,
      0.288,
      0.308,
      0.328,
      0.347,
      0.367,
      0.387,
      0.407,
      0.427,
      0.446,
      0.466
    ],
    "atk": [
      47,
      60,
      73,
      86,
      100,
      113,
      126,
      139,
      152,
      166,
      179,
      192,
      205,
      219,
      232,
      245,
      258,
      272,
      285,
      298,
      311
    ],
    "atk_": [
      0.07,
      0.09,
      0.11,
      0.129,
      0.149,
      0.169,
      0.189,
      0.209,
      0.228,
      0.248,
      0.268,
      0.288,
      0.308,
      0.328,
      0.347,
      0.367,
      0.387,
      0.407,
      0.427,
      0.446,
      0.466
    ],
    "def_": [
      0.087,
      0.112,
      0.137,
      0.162,
      0.186,
      0.211,
      0.236,
      0.261,
      0.286,
      0.31,
      0.335,
      0.36,
      0.385,
      0.409,
      0.434,
      0.459,
      0.484,
      0.508,
      0.533,
      0.558,
      0.583
    ],
    "critRate_": [
      0.047,
      0.06,
      0.073,
      0.086,
      0.099,
      0.113,
      0.126,
      0.139,
      0.152,
      0.166,
      0.179,
      0.192,
      0.205,
      0.218,
      0.232,
      0.245,
      0.258,
      0.271,
      0.284,
      0.298,
      0.311
    ],
    "critDMG_": [
      0.093,
      0.12,
      0.146,
      0.173,
      0.199,
      0.225,
      0.252,
      0.278,
      0.305,
      0.331,
      0.357,
      0.384,
      0.41,
      0.437,
      0.463,
      0.49,
      0.516,
      0.542,
      0.569,
      0.595,
      0.622
    ],
    "eleMas": [
      28,
      35.9,
      43.8,
      51.8,
      59.7,
      67.6,
      75.5,
      83.5,
      91.4,
      99.3,
      107.2,
      115.2,
      123.1,
      131,
      138.9,
      146.9,
      154.8,
      162.7,
      170.6,
      178.6,
      186.5
    ],
    "enerRech_": [
      0.078,
      0.1,
      0.122,
      0.144,
      0.166,
      0.188,
      0.21,
      0.232,
      0.254,
      0.276,
      0.298,
      0.32,
      0.342,
      0.364,
      0.386,
      0.408,
      0.43,
      0.452,
      0.474,
      0.496,
      0.518
    ],
    "heal_": [
      0.054,
      0.069,
      0.084,
      0.1,
      0.115,
      0.13,
      0.145,
      0.161,
      0.176,
      0.191,
      0.206,
      0.221,
      0.237,
      0.252,
      0.267,
      0.282,
      0.298,
      0.313,
      0.328,
      0.343,
      0.359
    ],
    "physical_dmg_": [
      0.087,
      0.112,
      0.137,
      0.162,
      0.186,
      0.211,
      0.236,
      0.261,
      0.286,
      0.31,
      0.335,
      0.36,
      0.385,
      0.409,
      0.434,
      0.459,
      0.484,
      0.508,
      0.533,
      0.558,
      0.583
    ],
    "electro_dmg_": [
      0.07,
      0.09,
      0.11,
      0.129,
      0.149,
      0.169,
      0.189,
      0.209,
      0.228,
      0.248,
      0.268,
      0.288,
      0.308,
      0.328,
      0.347,
      0.367,
      0.387,
      0.407,
      0.427,
      0.446,
      0.466
    ],
    "geo_dmg_": [
      0.07,
      0.09,
      0.11,
      0.129,
      0.149,
      0.169,
      0.189,
      0.209,
      0.228,
      0.248,
      0.268,
      0.288,
      0.308,
      0.328,
      0.347,
      0.367,
      0.387,
      0.407,
      0.427,
      0.446,
      0.466
    ],
    "pyro_dmg_": [
      0.07,
      0.09,
      0.11,
      0.129,
      0.149,
      0.169,
      0.189,
      0.209,
      0.228,
      0.248,
      0.268,
      0.288,
      0.308,
      0.328,
      0.347,
      0.367,
      0.387,
      0.407,
      0.427,
      0.446,
      0.466
    ],
    "hydro_dmg_": [
      0.07,
      0.09,
      0.11,
      0.129,
      0.149,
      0.169,
      0.189,
      0.209,
      0.228,
      0.248,
      0.268,
      0.288,
      0.308,
      0.328,
      0.347,
      0.367,
      0.387,
      0.407,
      0.427,
      0.446,
      0.466
    ],
    "cryo_dmg_": [
      0.07,
      0.09,
      0.11,
      0.129,
      0.149,
      0.169,
      0.189,
      0.209,
      0.228,
      0.248,
      0.268,
      0.288,
      0.308,
      0.328,
      0.347,
      0.367,
      0.387,
      0.407,
      0.427,
      0.446,
      0.466
    ],
    "anemo_dmg_": [
      0.07,
      0.09,
      0.11,
      0.129,
      0.149,
      0.169,
      0.189,
      0.209,
      0.228,
      0.248,
      0.268,
      0.288,
      0.308,
      0.328,
      0.347,
      0.367,
      0.387,
      0.407,
      0.427,
      0.446,
      0.466
    ]
  }
}